import * as React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <div className="flex flex-col min-h-full py-12 bg-white lg:py-16">
      <main className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="pb-16">
          <div className="text-center">
            <p className="text-base font-semibold tracking-wider uppercase text-primary">404 error</p>
            <h3 className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 md:text-5xl">Page not found.</h3>
            <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="mt-6">
              <Link to="/" className="text-base font-medium text-secondary hover:text-secondary">
                Go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <footer className="flex-shrink-0 w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <Link to="/residential" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Residential
          </Link>
          <span className="inline-block border-l border-gray-300" aria-hidden="true" />
          <Link to="/commercial" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Commercial
          </Link>
          <span className="inline-block border-l border-gray-300" aria-hidden="true" />
          <Link to="/financing" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Financing
          </Link>
          <span className="inline-block border-l border-gray-300" aria-hidden="true" />
          <Link to="/contact" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Contact us
          </Link>
        </nav>
      </footer>
    </div>
  </Layout>
)

export default NotFoundPage
